body {
  color: #033140;
  font-family: 'Foco','Proxima Nova', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 58rem;
  padding: 0;
}

canvas {
  position: absolute;
  left: 15%;
  top: 10rem;
}

.header, .opener, .services, .philosophy, .testimonials, .bio, .practice, .footer, .cta {
  display: flex;
  flex-wrap: wrap;
  padding: 0 15%;
}

.footer, .cta {
  justify-content: space-between;
}

.opener div, .services > div, .bio div {
  max-width: 50%;
}

.header img, .cta img {
  border-radius: 50%;
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  color: white;
}

p {
  font-size: .75rem;
}

h1,h2,h3,h4,h5 {
  margin: 0;
  font-weight: normal;
}

h2 {
  width: 100%;
  font-size: 1.5rem;
  margin: 4rem 0 2rem 0;
  padding-bottom: 1rem;
  border-bottom: 2px solid #033140;
}

h3 {
  font-size: 1.06rem;
  margin: 2rem 0;
}

h4 {
  font-size: .8rem;
  margin: .8rem 0 0 0;
  font-style: italic;
  font-family: 'Baskerville', serif;
}

figure {
  width: 50%;
}

.services figure {
  display: flex;
  align-items: center;
}

figure img {
  max-width: 20rem;
  padding: 0 2rem 2rem 2rem;
}

button {
  background-color: #35B9F1;
  color: white;
  padding: 1rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: .78rem;
  border: none;
  font-family: 'Foco','Proxima Nova',sans-serif;
}

button:hover {
  background-color: #F0B152;
  cursor: pointer;
}

span {
  background-color: #0A2E74;
}

.header {
  flex-direction: column;
  background-color: #FBFFF4;
  padding: 8% 15% 0 15%;
}

.header img {
  max-width: 3.5rem;
}

.header h1 {
  font-size: 1.62rem;
  margin: .6rem 1rem;
  border-bottom: 2px solid #B8DC53;
  padding-bottom: .2rem;
}

.header h4 {
  font-size: 1rem;
}

.header div {
  display: flex;
  align-items: center;
}

.opener {
  background-color: #FBFFF4;
}

.opener div:nth-of-type(2) {
  /* background-color: #FCFCFF; */
  padding-bottom: 2rem;
}

.opener h2 {
  border: 0;
}

.opener figure img {
  padding: 0;
  margin-top: 2rem;
}

.opener > figure > img {
  width:80%;
  margin: -2rem 4rem 1rem 4rem;
}

.opener button {
  margin-top: 1rem;
}

.opener > div:nth-of-type(2) {
  text-align: center;
}

ul {
  position: relative;
  list-style: none;
}

li {
  font-size:1rem;
  font-style: italic;
  margin: 1rem 0;
}

li::before {
  content:"*";
  color: #6E56FD;
  font-weight: bold;
  left: .34rem;
  position: absolute;
}

.services > div:last-of-type > div > p:first-of-type :not(.exempt) {
  border-left: 2px solid #35B9F1;
  padding-left: .6rem;
  font-family: 'Baskerville',serif;
  font-size:1rem;
  font-style: italic;
  display: inline;
}

.services > div:last-of-type > div > img {
  max-height:2rem;
  display: inline;
  margin-left: 1rem;
}

.services h3 {
  position: relative;
}

.services h3::before {
  content:"1";
  width:1rem;
  height:1rem;
  color:#E0E7B5;
  font-family: serif;
  font-size:3rem;
  font-weight: 300;
  font-style: italic;
  position: absolute;
  top: -1.2rem;
  left: 1rem;
  z-index: -1;
}

.services > div:nth-of-type(2) > h3::before {
  content:"2";
}

.services > div:nth-of-type(3) > h3::before {
  content:"3";
}

.services > figure:first-of-type img {
  max-height: 400px;
  margin: auto;
}

.services > div {
  margin-bottom: 2rem;
}



.bio {
  width:100%;
  color: white;
  background-image: linear-gradient(rgba(0, 0, 0, 0.934),rgba(0, 0, 0, 0.331));
  position: relative;
  overflow: hidden;
}

.bio video {
  position: absolute;
  left: 0;
  width: auto;
  height: 104%;
  z-index: -1;
}

.bio figure img {
  padding: 1rem 0 0rem 2rem;
}

.bio h2 {
  border-color: white;
}

.bio p {
  margin: 1.2rem 0;
}

.philosophy p {
  margin: 2rem 0;
}

.philosophy img {
  max-height: 4rem;
}

.philosophy img {
  padding: 0 2rem;
  margin: 1rem 0;
}

.philosophy div {
  display: flex;
  flex-wrap: wrap;
}

.philosophy p:nth-child(4n), .testimonials div:nth-child(4n) {
  width: 75%;
}

.philosophy p:nth-child(4n+1), .testimonials div:nth-child(4n+1) {
  width: 75%;
}

.practice {
  position: relative;
  justify-content: space-between;
}

.practice p {
  max-width: 12rem;
  margin: 0 1rem;
}

.practice img {
  max-height: 4.5rem;
  width: auto;
  margin: 2rem auto;
  display: block;
}

.practice div:first-of-type p {
  margin-left: 0;
}

.practice div:last-of-type p {
  margin-right: 0;
}

.cta {
  background-color: #FDFDF4;
  align-items: center;
  margin: 2rem 0;
  flex-direction: row;
}

.cta > div:first-of-type {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2rem 0;
  max-width: 50%;
}

.cta img {
  width: 4.5rem;
  height: 4.5rem;
}

.cta h3, .cta p {
  margin: 0 0 0 1rem;
}

.cta > div:last-of-type {
  background-image: url(./landscape-button-bg.svg);
  background-repeat: no-repeat;
  background-size: contain;
  padding: 1rem;
}

.podcast {
  display: flex;
  flex-direction: row;
  background-color: #A4B8FE;
  color: white;
  border-radius: 1rem;
  padding: 2rem;
  justify-content: space-between;

  max-width: 75%;
  margin: auto;
}

.podcast > div {
  width: 45%;
}

.podcast > div:last-of-type {
  padding: 1.4rem;
  background-color: #3C3685;
  border-radius: 1rem;
}

.podcast > div:last-of-type h4 {
  display: inline;
}

.podcast > div:last-of-type h3 {
  margin: .5rem 0;
}

.podcast img.logo {
  max-width: 75%;
  margin-right: 15%;
}
.podcast img.icon {
  max-width:2rem;
  margin-top: 1rem;
}

.arrow-right {
  width: 0; 
  height: 0; 
  border-top: .4rem solid transparent;
  border-bottom: .4rem solid transparent;
  border-left: .4rem solid white;
  display:inline-block;
  margin-left: .8rem;
}

.testimonials div p {
  margin-left: 2rem;
}

.testimonials div {
  margin: 1rem 0;
}

.testimonials p:last-of-type {
  font-weight: bold;
}

.testimonials div:nth-child(2n+1) {
  margin-left: 25%;
}

.testimonials div:nth-child(2n) {
  max-width:75%;
}

.testimonials div p {
  /* font-family: 'Georgia', serif; */
  font-style: italic;
  text-align: justify;
  position: relative;
}

.testimonials div p:first-of-type::before {
  content: open-quote;
  position: absolute;
  font-size: 3rem;
  left: -2rem;
  top: -1rem;
  color: #E0E7B5;
}

.testimonials div p:last-of-type::after {
  content: close-quote;
  position: absolute;
  font-size: 2rem;
  right: 1rem;
  top:-2rem;
  color: #E0E7B5;
}

.footer {
  border-top: 2px solid #033140;
  padding-top:2rem;
  padding-bottom:2rem;
  background: url('./footerbg.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 500px;
  background-position-x: center;
}

.footer p {
  margin: 0;
}

.footer h3 {
  margin: 0;
}

@media screen and (max-width: 1200px) {
  .header, .opener, .services, .philosophy, .testimonials, .bio, .practice, .footer, .cta {
    display: flex;
    flex-wrap: wrap;
    padding: 0 5%;
  }
  .footer {
    padding: 5%;
  }
}

@media screen and (max-width: 924px) {
  body {
    padding: 5%;
  }
  .header {
    padding-top: 4rem;
  }
  .opener div:nth-of-type(2), .practice div, .cta div, .philosophy img {
    text-align: center;
    margin: auto;
  }
  .opener div, .services > div, .bio div {
    max-width: 100%;
  }
  .opener > figure img {
    width: 100%;
    margin: 2rem 0;
  }
  figure {
    width:100%;
    text-align: center;
  }
  figure img {
    width: 100%;
  }
  .bio figure img {
    padding: 1rem 0;
  }
  .testimonials > div {
    margin-left: 0;
    width: 100%;
  }
  .testimonials div > p {
    margin: 0;
  }
  .cta > div:first-of-type {
    flex-direction: column;
  }
  .cta > div:last-of-type {
    background-image: url(/static/media/landscape-button-bg.bcdf88cc.svg);
    background-repeat: no-repeat;
    background-size: contain;
    padding: 2rem;
    margin: auto;
  }
  .cta h3, .cta p {
    margin: 2rem 0 0 0;
  }
  .cta {
    text-align: center;
    flex-direction: column;
  }
  .podcast {
    max-width: 100%;
    flex-direction: column;
  }
  .podcast >div {
    width: 100%;
  }
  .philosophy p {
    width: 100% !important;
  }
  .practice {
    flex-direction: column;
  }
  .footer div, .footer {
    margin: 2rem 0;
  }
  #animCanvas {
    display: none;
  }
}